import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from '../constants/ThemeConstant'
import { env } from './EnvironmentConfig'

export const APP_NAME = 'MUSLISTING'
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '/app'
export const AUTH_PREFIX_PATH = '/auth'
export const REDIRECT_URL_KEY = 'redirect'
// export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/dashboards/default`
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/user/manage`

export const UNAUTHENTICATED_ENTRY = '/login'

export const THEME_CONFIG = {
    navCollapsed: false,
    sideNavTheme: SIDE_NAV_LIGHT,
    locale: 'en',
    navType: NAV_TYPE_SIDE,
    topNavColor: '#3e82f7',

    headerNavColor: '',
    mobileNav: false,
    currentTheme: 'light',
    direction: DIR_LTR,
    blankLayout: false,
}

export const GLOBAL_CONSTANTS = {
    CATEGORY_RESTAURANT: '18EsrAoX6Tl0rndhSHxnzTBFVW2i9F',
}

export const USER_ROUTE = {
    MANAGE: '/user/manage',
    UPDATE: '/user/update',
    CREATE: '/user/create',
    ACCOUNT_SETTING: '/user/account-setting',
    FOODIE_REQUEST: '/user/foodie-request/manage',
}

export const BUSINESS_ROUTE = {
    BUSINESS: '/business',
    MANAGE: '/business/manage',
    CREATE: '/business/create',
    UPDATE: '/business/update',
    VIEW: '/business/view/:id',
    IMPORT: '/business/import/file',
    BUSINESS_CLAIMS: '/business/claims',
}

export const BUSINESS_DATA_ROUTE = {
    SEARCH: '/data/search',
    ADD: '/data/add',
}

export const SETTINGS_ROUTE = {
    CATEGORIES_MANAGE: '/settings/category/manage',
    CUISINE_MANAGE: '/settings/cuisine/manage',
}
