import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH, USER_ROUTE, BUSINESS_ROUTE, BUSINESS_DATA_ROUTE, SETTINGS_ROUTE } from '../configs/AppConfig'

export interface IRoutes {
    key: string
    path: string
    component: React.LazyExoticComponent<(props: any) => JSX.Element> | any
    meta?: any
}

export const publicRoutes: IRoutes[] = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('../views/auth-views/authentication/login')),
    },
]

export const protectedRoutes: IRoutes[] = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('../views/app-views/dashboards/default')),
    },
    {
        key: 'section.user',
        path: `${APP_PREFIX_PATH}${USER_ROUTE.MANAGE}`,
        component: React.lazy(() => import('../views/app-views/section/user-list')),
    },
    {
        key: 'section.foodie-request',
        path: `${APP_PREFIX_PATH}${USER_ROUTE.FOODIE_REQUEST}`,
        component: React.lazy(() => import('../views/app-views/section/foodie-request')),
    },
    {
        key: 'section.business-claims',
        path: `${APP_PREFIX_PATH}${BUSINESS_ROUTE.BUSINESS_CLAIMS}`,
        component: React.lazy(() => import('../views/app-views/section/business-claims')),
    },
    {
        key: 'section.edit-user',
        path: `${APP_PREFIX_PATH}${USER_ROUTE.UPDATE}/:id`,
        component: React.lazy(() => import('../views/app-views/section/user-list/edit-user')),
    },
    {
        key: 'section.add-user',
        path: `${APP_PREFIX_PATH}${USER_ROUTE.CREATE}`,
        component: React.lazy(() => import('../views/app-views/section/user-list/add-user')),
    },
    {
        key: 'section.business',
        path: `${APP_PREFIX_PATH}${BUSINESS_ROUTE.MANAGE}`,
        component: React.lazy(() => import('../views/app-views/section/business-list')),
    },
    {
        key: 'section.business.import',
        path: `${APP_PREFIX_PATH}${BUSINESS_ROUTE.IMPORT}`,
        component: React.lazy(() => import('../views/app-views/section/business-list/import-business')),
    },
    {
        key: 'section.update-business',
        path: `${APP_PREFIX_PATH}${BUSINESS_ROUTE.UPDATE}/:id`,
        component: React.lazy(() => import('../views/app-views/section/business-list/edit-business')),
    },
    {
        key: 'section.create-business',
        path: `${APP_PREFIX_PATH}${BUSINESS_ROUTE.CREATE}`,
        component: React.lazy(() => import('../views/app-views/section/business-list/add-business')),
    },
    {
        key: 'section.view-business',
        path: `${APP_PREFIX_PATH}${BUSINESS_ROUTE.VIEW}`,
        component: React.lazy(() => import('../views/app-views/section/business-list/view-business')),
    },
    {
        key: 'section.search',
        path: `${APP_PREFIX_PATH}${BUSINESS_DATA_ROUTE.SEARCH}`,
        component: React.lazy(() => import('../views/app-views/section/search')),
    },
    {
        key: 'section.add-data',
        path: `${APP_PREFIX_PATH}${BUSINESS_DATA_ROUTE.ADD}`,
        component: React.lazy(() => import('../views/app-views/section/add-data')),
    },
    {
        key: 'account-setting',
        path: `${APP_PREFIX_PATH}${USER_ROUTE.ACCOUNT_SETTING}`,
        component: React.lazy(() => import('../views/app-views/section/account-setting')),
    },
    {
        key: 'setting.business-category',
        path: `${APP_PREFIX_PATH}${SETTINGS_ROUTE.CATEGORIES_MANAGE}`,
        component: React.lazy(() => import('../views/app-views/section/settings/business-categories')),
    },
    {
        key: 'setting.business-cuisine',
        path: `${APP_PREFIX_PATH}${SETTINGS_ROUTE.CUISINE_MANAGE}`,
        component: React.lazy(() => import('../views/app-views/section/settings/business-cuisines')),
    },
]
